import React from "react";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import tableNews from "../../../_params/tab-pages/home/tab-news";

const News = () => {
  return (
    <>
      <Col xs={12} md={{ span: 7, offset: 0 }} lg={5} className="p-4">
        <Carousel fade className="text-light">
          {tableNews.map((news) => (
            <Carousel.Item key={news.id}>
              <a href={news.link}>
                <img
                  className="w-100 d-block border border-2 rounded-3"
                  src={news.img}
                  alt="Missing slide"
                />
              </a>
              <div className="border p-2 bg-secondary border-top-0 border-2 rounded-3">
                <h5>{news.title}</h5>
                <a
                  href={news.link}
                  className="link-light link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-offset-2"
                >
                  <p>{news.texte}</p>
                </a>
                <br />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </>
  );
};

export default News;
