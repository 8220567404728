import members2022 from "../members/members2022.csv";
import members2023 from "../members/members2023.csv";
import members2024 from "../members/members2024.csv";
import members2025 from "../members/members2025.csv";

const tabCsvFiles = [
  {
    season: "2022",
    file: members2022,
  },
  {
    season: "2023",
    file: members2023,
  },
  {
    season: "2024",
    file: members2024,
  },
  {
    season: "2025",
    file: members2025,
  },
];

export default tabCsvFiles;
