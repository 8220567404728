import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import tabActivitesClub from "../../../_params/tab-pages/programme/tab-activitesclub";
import tabActivitesJeunes from "../../../_params/tab-pages/programme/tab-activitesjeunes";
import SideMenu from "../../../components/menus/m-side";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../_utils/context/navheightprovider";
import tableSaisons from "../../../_params/tab-config/tab-saisons";
import "../../../assets/css/table.css";

const Programme = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();

  // Tri par ordre croissant sur les dates
  tabActivitesJeunes.sort((a, b) => a.dateDebut - b.dateDebut);
  tabActivitesClub.sort((a, b) => a.dateDebut - b.dateDebut);

  /*************************** Selection of today's date to select current season year */
  let year = 0;

  const todaysDate = new Date();
  for (var i = 0; i < tableSaisons.length; i++) {
    const startDate = Date.parse(tableSaisons[i].startDate);
    const endDate = Date.parse(tableSaisons[i].endDate);

    if (todaysDate > startDate && todaysDate < endDate) {
      year = tableSaisons[i].season;
      break;
    }
  }

  return (
    <div className="mc-fond-programme">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              background: "rgba(0, 43, 110, 0.5)",
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="gx-0">
            {/***********************************************************************/}
            {/*************************** menu breadcrumb ***************************/}
            {/***********************************************************************/}

            <MenuBreadcrumbEvents />

            {/*********************************************************************/}
            {/************************** Titre ************************************/}
            {/*********************************************************************/}
            <Card
              className="m-2 border-0"
              style={{ backgroundColor: "rgba(110, 43, 110, 0.5)" }}
            >
              <Card.Header className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0 text-center bg-danger-subtle">
                SAISON {year}
              </Card.Header>
            </Card>

            {/***********************************************************************/}
            {/*********************************************** Partie Activités Club */}
            {/***********************************************************************/}

            <Card bg="transparent" className="my-2 border-0">
              <span
                className="mc-marker"
                style={{
                  top: -(navbarHeight + sideNavHeight + breadNavHeight),
                }}
                id="activitesclub"
              ></span>
              <Card.Body
                style={{ backgroundColor: "rgba(110, 43, 110, 1)" }}
                className="m-2 p-2 rounded"
              >
                <Card.Header
                  className="mc-rampart mc-rfs-2 m-2 text-primary rounded"
                  style={{ backgroundColor: "rgba(255, 233, 255, 1)" }}
                >
                  Activités Club
                </Card.Header>
              </Card.Body>
              <Card.Body>
                <Table responsive bordered className="mc-table">
                  <thead>
                    <tr>
                      <th>Animations</th>
                      <th>Description</th>
                      <th>Date début</th>
                      <th>Date fin</th>
                      <th>Lieu</th>
                    </tr>
                  </thead>

                  {tabActivitesClub.map((activite) => (
                    <tbody key={activite.id}>
                      <tr>
                        <td>{activite.animation}</td>
                        <td>{activite.desc}</td>
                        <td>{activite.dateDebut}</td>
                        <td>{activite.dateFin}</td>
                        <td>{activite.lieu}</td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Card.Body>
              <Card.Body className="m-2 p-2 bg-light">
                <span className="text-primary">
                  Si vous avez des idées de nouvelles activités,
                </span>
                <a href="/contact" className="text-decoration-none">
                  <span className="mc-nous-contacter ps-1 fw-bold fs-5 fst-italic">
                    contactez-nous
                  </span>
                </a>
              </Card.Body>
            </Card>

            {/***********************************************************************/}
            {/***************************** Partie Activités spécifiques aux jeunes */}
            {/***********************************************************************/}

            <Card bg="transparent" className="my-2 border-0">
              <span
                className="mc-marker"
                style={{
                  top: -(navbarHeight + sideNavHeight + breadNavHeight),
                }}
                id="activitesjeunes"
              ></span>
              <Card.Body
                style={{ backgroundColor: "rgba(110, 43, 110, 1)" }}
                className="my-4 mx-2 p-2 rounded"
              >
                <Card.Header
                  className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0"
                  style={{ backgroundColor: "rgba(255, 233, 255, 1)" }}
                >
                  Activités Jeunes
                </Card.Header>
              </Card.Body>
              <Card.Body>
                <Table responsive bordered className="mc-table">
                  <thead>
                    <tr>
                      <th>Animations</th>
                      <th>Description</th>
                      <th>Date début</th>
                      <th>Date fin</th>
                      <th>Lieu</th>
                    </tr>
                  </thead>

                  {tabActivitesJeunes.map((activite) => (
                    <tbody key={activite.id}>
                      <tr>
                        <td>{activite.animation}</td>
                        <td>{activite.desc}</td>
                        <td>{activite.dateDebut}</td>
                        <td>{activite.dateFin}</td>
                        <td>{activite.lieu}</td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Card.Body>
              <Card.Body className="m-2 p-2 bg-light">
                <span className="text-primary">
                  Si vous avez des idées de nouvelles activités,
                </span>
                <a href="/contact" className="text-decoration-none">
                  <span className="mc-nous-contacter ps-1 fw-bold fs-5 fst-italic">
                    contactez-nous
                  </span>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Programme;
