const tableCompetitions = [
  {
    competitionId: 1,
    season: "2023",
    championship: "Départemental",
    category: "11/12 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "D",
        path: "https://tenup.fft.fr/championnat/82346138?division=116140&phase=185154&poule=426561",
        players: [
          {
            playerName: "SALGADO Rafael",
            email: "gmarechal01@gmail.com",
            tel: "06.43.84.05.43",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4546267 U",
          },
          {
            playerName: "GREHAL Leon",
            email: "maxime.grehal@gmail.com",
            tel: "06.70.53.67.90",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1022435 S",
          },
          {
            playerName: "LAY Aslan",
            email: "lay.stephane@wanadoo.fr",
            tel: "06.13.63.87.01",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1047355 D",
          },
        ],
      },
      {
        teamNum: 2,
        level: "Division 2",
        group: "E",
        path: "https://tenup.fft.fr/championnat/82346138?division=116141&phase=185155&poule=426566",
        players: [
          {
            playerName: "PONCET Victor",
            email: "fd.poncet@orange.fr",
            tel: "06.72.82.36.27",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "4545533 W",
          },
          {
            playerName: "GUILLEMIN Aloïs",
            email: "guillemin.guillaume@orange.fr",
            tel: "06.59.27.42.02",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "0595594 J",
          },
          {
            playerName: "MATRAS ezequiel",
            email: "matmatras@yahoo.fr",
            tel: "06.65.81.01.36",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "9923587 G",
          },
        ],
      },
    ],
  },
  {
    competitionId: 2,
    season: "2023",
    championship: "Départemental",
    category: "15/18 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 3",
        group: "C",
        path: "https://tenup.fft.fr/championnat/82346141?division=116577&phase=185770&poule=427690",
        players: [
          {
            playerName: "DEMIRAY Kenan",
            email: "kupraa@gmail.com",
            tel: "06.69.13.07.90",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "4440129 B",
          },
          {
            playerName: "SURACE Adrien",
            email: "sandrine.surace@sfr.fr",
            tel: "06.43.70.43.56",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "1634354 W",
          },
          {
            playerName: "GALLION Nicolas",
            email: "laurablue2907@hotmail.com",
            tel: "06.62.43.38.65",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "8727425 F",
          },
        ],
      },
    ],
  },
  {
    competitionId: 3,
    season: "2023",
    championship: "Départemental",
    category: "15/18 ans",
    gender: "Filles",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "1",
        path: "https://tenup.fft.fr/championnat/82346140?division=116572&phase=185763&poule=427621",
        players: [
          {
            playerName: "ALBAN Eléna",
            email: "alban.ceciliaetstephane@neuf.fr",
            tel: "06.35.25.88.90",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "6162636 S",
          },
          {
            playerName: "LAURENT Andrea",
            email: "andrealaurent202@gmail.com",
            tel: "06.66.08.01.62",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "9212356 E",
          },
          {
            playerName: "GRANGER Noémie",
            email: "vergranger@orange.fr",
            tel: "06.25.70.61.32",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4540484 H",
          },
        ],
      },
    ],
  },
  {
    competitionId: 4,
    season: "2023",
    championship: "Coupe Henry Genéty",
    category: "13/14 ans",
    gender: "Mixte",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "A",
        path: "https://tenup.fft.fr/championnat/82367960?division=119945&phase=191721&poule=438110",
        players: [
          {
            playerName: "GRANGER Noémie",
            email: "vergranger@orange.fr",
            tel: "06.25.70.61.32",
            ranking: "30/3",
            rankingDouble: "15",
            licenceNum: "4540484 H",
          },
          {
            playerName: "SALGADO Rafael",
            email: "gmarechal01@gmail.com",
            tel: "06.43.84.05.43",
            ranking: "30/3",
            rankingDouble: "15",
            licenceNum: "4546267 U",
          },
          {
            playerName: "ALBAN Quentin",
            email: "alban.ceciliaetstephane@neuf.fr",
            tel: "06.73.24.76.98",
            ranking: "40",
            rankingDouble: "18",
            licenceNum: "8022720 X",
          },
          {
            playerName: "PANIE César",
            email: "jeromepanie@gmail.com",
            tel: "06.88.32.78.49",
            ranking: "40",
            rankingDouble: "18",
            licenceNum: "4544557 K",
          },
        ],
      },
    ],
  },
  {
    competitionId: 5,
    season: "2023",
    championship: "Ligue",
    category: "Seniors",
    gender: "Messieurs",
    teams: [
      {
        teamNum: 1,
        level: "Ligue 3",
        group: "H",
        path: "https://tenup.fft.fr/championnat/82335989?division=114404&phase=182814&poule=439245",
        players: [
          {
            playerName: "BRESSAN Loic",
            email: "loicbressan6@gmail.com",
            tel: "06.50.78.05.81",
            ranking: "15/2",
            doubleRanking: "8",
            licenceNum: "2205855 T",
          },
          {
            playerName: "SCHULLER Antoine",
            email: "antoine.schuller@yahoo.fr",
            tel: "06.10.34.28.46",
            ranking: "15/2",
            doubleRanking: "8",
            licenceNum: "6348702 M",
          },
          {
            playerName: "DU ROURE Ludovic",
            email: "ludo-71@hotmail.fr",
            tel: "06.73.04.93.20",
            ranking: "15/2",
            doubleRanking: "18",
            licenceNum: "2317909 R",
          },
          {
            playerName: "JARILLO Lonny",
            email: "lonny.jarillo@icloud.com",
            tel: "07.67.83.10.08",
            ranking: "15/2",
            doubleRanking: "8",
            licenceNum: "6423069 W",
          },
          {
            playerName: "VIDON Alexandre",
            email: "alexandre.vidon@gmail.com",
            tel: "06.60.31.14.62",
            ranking: "15/4",
            doubleRanking: "10",
            licenceNum: "3919020 D",
          },
          {
            playerName: "DEFALLETANS Benoit",
            email: "bendefalletans@gmail.com",
            tel: "06.11.65.97.15",
            ranking: "15/5",
            doubleRanking: "11",
            licenceNum: "5868577 M",
          },
          {
            playerName: "SINIBALDI Thomas",
            email: "thomasinibaldi@gmail.com",
            tel: "06.58.91.15.06",
            ranking: "15/5",
            doubleRanking: "11",
            licenceNum: "3025132 L",
          },
          {
            playerName: "GIRARD Clément",
            email: "clement0171@free.fr",
            tel: "06.95.29.02.14",
            ranking: "15/5",
            doubleRanking: "11",
            licenceNum: "0531762 B",
          },
        ],
      },
    ],
  },
  {
    competitionId: 6,
    season: "2023",
    championship: "Départemental",
    category: "Seniors",
    gender: "Messieurs",
    teams: [
      {
        teamNum: 2,
        level: "Division 3",
        group: "E",
        path: "https://tenup.fft.fr/championnat/82370648?division=119128&phase=190362&poule=440768",
        players: [
          {
            playerName: "VIDON Alexandre",
            email: "alexandre.vidon@gmail.com",
            tel: "06.60.31.14.62",
            ranking: "15/4",
            doubleRanking: "10",
            licenceNum: "3919020 D",
          },
          {
            playerName: "SINIBALDI Thomas",
            email: "thomasinibaldi@gmail.com",
            tel: "06.58.91.15.06",
            ranking: "15/5",
            doubleRanking: "11",
            licenceNum: "3025132 L",
          },
          {
            playerName: "GIRARD Clément",
            email: "clement0171@free.fr",
            tel: "06.95.29.02.14",
            ranking: "15/5",
            doubleRanking: "11",
            licenceNum: "0531762 B",
          },
          {
            playerName: "DEFALLETANS Benoit",
            email: "bendefalletans@gmail.com",
            tel: "06.11.65.97.15",
            ranking: "15/5",
            doubleRanking: "11",
            licenceNum: "5868577 M",
          },
          {
            playerName: "PERRIN Thomas",
            email: "thomas.perrinissautier@gmail.com",
            tel: "06.37.30.60.91",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "4924761 A",
          },
          {
            playerName: "CEDDIA Michel",
            email: "ceddiam@aol.com",
            tel: "06.82.94.80.94",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7628206 C",
          },
          {
            playerName: "MARTIN Alexandre",
            email: "alex.martin71@wanadoo.fr",
            tel: "06.29.51.49.28",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "2317754 X",
          },
          {
            playerName: "PENIN Gonzague",
            email: "penin.gonzague@outlook.com",
            tel: "06.71.32.31.85",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "1047404 G",
          },
          {
            playerName: "MATRAS Mathieu",
            email: "matmatras@yahoo.fr",
            tel: "06.07.43.71.15",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7486886 U",
          },
          {
            playerName: "MEYER Yan",
            email: "playti.meyer837101@free.fr",
            tel: "06.68.76.00.63",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7303664 P",
          },
        ],
      },
      {
        teamNum: 3,
        level: "Division 3",
        group: "B",
        path: "https://tenup.fft.fr/championnat/82370648?division=119128&phase=190362&poule=440765",
        players: [
          {
            playerName: "PERRIN Thomas",
            email: "thomas.perrinissautier@gmail.com",
            tel: "06.37.30.60.91",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "4924761 A",
          },
          {
            playerName: "PENIN Gonzague",
            email: "penin.gonzague@outlook.com",
            tel: "06.71.32.31.85",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "1047404 G",
          },
          {
            playerName: "MARTIN Alexandre",
            email: "alex.martin71@wanadoo.fr",
            tel: "06.29.51.49.28",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "2317754 X",
          },
          {
            playerName: "CEDDIA Michel",
            email: "ceddiam@aol.com",
            tel: "06.82.94.80.94",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7628206 C",
          },
          {
            playerName: "MEYER Yan",
            email: "playti.meyer837101@free.fr",
            tel: "06.68.76.00.63",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7303664 P",
          },
          {
            playerName: "MATRAS Mathieu",
            email: "matmatras@yahoo.fr",
            tel: "06.07.43.71.15",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7486886 U",
          },
          {
            playerName: "BRUNET Nicolas",
            email: "nbrunet40@neuf.fr",
            tel: "06.86.33.70.22",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "1338296 U",
          },
          {
            playerName: "BROISE Gérard",
            email: "inf.broise@wanadoo.fr",
            tel: "06.61.41.55.88",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "5564257 E",
          },
          {
            playerName: "CLAIR Benjamin",
            email: "clairbenjamin71@gmail.com",
            tel: "06.43.93.15.99",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1990929 C",
          },
          {
            playerName: "GATINET Francois",
            email: "fxparisien71@hotmail.fr",
            tel: "06.60.85.07.50",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1071895 C",
          },
        ],
      },
      {
        teamNum: 4,
        level: "Division 4",
        group: "G",
        path: "https://tenup.fft.fr/championnat/82370648?division=119127&phase=190361&poule=440796",
        players: [
          {
            playerName: "MEYER Yan",
            email: "playti.meyer837101@free.fr",
            tel: "06.68.76.00.63",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7303664 P",
          },
          {
            playerName: "BRUNET Nicolas",
            email: "nbrunet40@neuf.fr",
            tel: "06.86.33.70.22",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "1338296 U",
          },
          {
            playerName: "MAZILLE Ludovic",
            email: "ludovic.mazille@sfr.fr",
            tel: "06.63.39.00.20",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "5103391 N",
          },
          {
            playerName: "CLAIR Benjamin",
            email: "clairbenjamin71@gmail.com",
            tel: "06.43.93.15.99",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1990929 C",
          },
          {
            playerName: "BROISE Gérard",
            email: "inf.broise@wanadoo.fr",
            tel: "06.61.41.55.88",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "5564257 E",
          },
          {
            playerName: "GATINET Francois",
            email: "fxparisien71@hotmail.fr",
            tel: "06.60.85.07.50",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1071895 C",
          },
          {
            playerName: "MARCHAND Frédéric",
            email: "fredericmarchand01@gmail.com",
            tel: "06.42.16.05.56",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1033027 E",
          },
          {
            playerName: "LETOURNEAUX Quentin",
            email: "quentin.letourneaux@free.fr",
            tel: "07.88.19.05.42",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "4627072 A",
          },
          {
            playerName: "CATHERIN Jérémie",
            email: "jeremie.catherin@gmail.com",
            tel: "06.21.78.60.43",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "8312714 H",
          },
          {
            playerName: "PETIT Hervé",
            email: "kekevalpetit@aol.com",
            tel: "06.84.70.35.01",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "0619205 Y",
          },
        ],
      },
    ],
  },
  {
    competitionId: 7,
    season: "2023",
    championship: "Départemental",
    category: "Seniors",
    gender: "Dames",
    teams: [
      {
        teamNum: 1,
        level: "Division 2",
        group: "A",
        path: "https://tenup.fft.fr/championnat/82370647?division=119132&phase=190366&poule=440810",
        players: [
          {
            playerName: "BEAUDEY Laurie",
            email: "beaudey.laurie@gmail.com",
            tel: "06.62.18.57.09",
            ranking: "15/3",
            doubleRanking: "9",
            licenceNum: "0610597 S",
          },
          {
            playerName: "PACCAUD lucie",
            email: "lucie-01@live.fr",
            tel: "06.10.61.24.80",
            ranking: "15/4",
            doubleRanking: "10",
            licenceNum: "4020204 L",
          },
          {
            playerName: "LAURENT Andrea",
            email: "andrealaurent202@gmail.com",
            tel: "06.66.08.01.62",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "9212356 E",
          },
          {
            playerName: "ALBAN Eléna",
            email: "alban.ceciliaetstephane@neuf.fr",
            tel: "06.35.25.88.90",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "6162636 S",
          },
          {
            playerName: "CEDDIA Laurence",
            email: "laurenceceddia@aol.com",
            tel: "06.59.77.38.69",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7966067 T",
          },
          {
            playerName: "CATHERIN Charlotte",
            email: "charlotte.catherin@gmail.com",
            tel: "06.31.39.71.42",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "8312679 V",
          },
        ],
      },
      {
        teamNum: 2,
        level: "Division 3",
        group: "F",
        path: "https://tenup.fft.fr/championnat/82370647?division=119131&phase=190365&poule=440819",
        players: [
          {
            playerName: "CATHERIN Charlotte",
            email: "charlotte.catherin@gmail.com",
            tel: "06.31.39.71.42",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "8312679 V",
          },
          {
            playerName: "DEFALLETANS Clotilde",
            email: "agnes.defalletans@gmail.com",
            tel: "06.11.65.97.15",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "2790780 F",
          },
          {
            playerName: "MARECHAL Gaëlle",
            email: "gmarechal01@gmail.com",
            tel: "06.43.84.05.43",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "7263130 F",
          },
          {
            playerName: "GRANGER Noémie",
            email: "vergranger@orange.fr",
            tel: "06.25.70.61.32",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4540484 H",
          },
          {
            playerName: "PONCET Florence",
            email: "fd.poncet@orange.fr",
            tel: "06.72.82.36.27",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4108295 M",
          },
          {
            playerName: "BOURDON Marie-Laure",
            email: "mlbourdon01@gmail.com",
            tel: "06.10.07.96.79",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1022364 P",
          },
          {
            playerName: "GRANGER Véronique",
            email: "vergranger@orange.fr",
            tel: "06.25.70.61.32",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "0594192 K",
          },
          {
            playerName: "GUERIN Christine",
            email: "christine.guerin1011@orange.fr",
            tel: "06.79.98.49.19",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1187689 R",
          },
          {
            playerName: "FARAONE Shauna",
            email: "shaunaf@hotmail.fr",
            tel: "06.59.52.03.88",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "7638012 L",
          },
          {
            playerName: "DIETRICH AURORE",
            email: "aurorechandon@outlook.fr",
            tel: "06.41.95.08.14",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "2121271 D",
          },
        ],
      },
    ],
  },
  {
    competitionId: 8,
    season: "2023",
    championship: "Interdépartemental",
    category: "Seniors 35+",
    gender: "Dames",
    teams: [
      {
        teamNum: 1,
        level: "Division 2",
        group: "T",
        path: "https://tenup.fft.fr/championnat/82336005?division=115087&phase=183775&poule=424659",
        players: [
          {
            playerName: "CEDDIA Laurence",
            email: "laurenceceddia@aol.com",
            tel: "06.59.77.38.69",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7966067 T",
          },
          {
            playerName: "MARECHAL Gaëlle",
            email: "gmarechal01@gmail.com",
            tel: "06.43.84.05.43",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "7263130 F",
          },
          {
            playerName: "PONCET Florence",
            email: "fd.poncet@orange.fr",
            tel: "06.72.82.36.27",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4108295 M",
          },
          {
            playerName: "GUERIN Christine",
            email: "christine.guerin1011@orange.fr",
            tel: "06.79.98.49.19",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1187689 R",
          },
          {
            playerName: "ZAGHDOUD Iman",
            email: "imane.j83@gmail.com",
            tel: "06.28.78.20.68",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "0717414 X",
          },
          {
            playerName: "GRANGER Véronique",
            email: "vergranger@orange.fr",
            tel: "06.25.70.61.32",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "0594192 K",
          },
        ],
      },
    ],
  },
  {
    competitionId: 9,
    season: "2023",
    championship: "Interdépartemental",
    category: "Seniors 35+",
    gender: "Messieurs",
    teams: [
      {
        teamNum: 1,
        level: "Division 3",
        group: "F",
        path: "https://tenup.fft.fr/championnat/82336006?division=114639&phase=183145&poule=425130",
        players: [
          {
            playerName: "CEDDIA Michel",
            email: "ceddiam@aol.com",
            tel: "06.82.94.80.94",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7628206 C",
          },
          {
            playerName: "PENIN Gonzague",
            email: "penin.gonzague@outlook.com",
            tel: "06.71.32.31.85",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "1047404 G",
          },
          {
            playerName: "MEYER Yan",
            email: "playti.meyer837101@free.fr",
            tel: "06.68.76.00.63",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7303664 P",
          },
          {
            playerName: "BRUNET Nicolas",
            email: "nbrunet40@neuf.fr",
            tel: "06.86.33.70.22",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "1338296 U",
          },
          {
            playerName: "CLAIR Benjamin",
            email: "clairbenjamin71@gmail.com",
            tel: "06.43.93.15.99",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1990929 C",
          },
          {
            playerName: "BROISE Gérard",
            email: "inf.broise@wanadoo.fr",
            tel: "06.61.41.55.88",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "5564257 E",
          },
        ],
      },
    ],
  },
  {
    competitionId: 10,
    season: "2024",
    championship: "Ligue",
    category: "Seniors 55+",
    gender: "Messieurs",
    teams: [
      {
        teamNum: 1,
        level: "Interdépartemantale 1",
        group: "K",
        path: "https://tenup.fft.fr/championnat/82397561",
        players: [
          {
            playerName: "CEDDIA Michel",
            email: " ceddiam@aol.com",
            tel: "06.82.94.80.94",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7628206 C",
          },
          {
            playerName: "MEYER Yan",
            email: "playti.meyer837101@free.fr",
            tel: "06.68.76.00.63",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7303664 0",
          },
          {
            playerName: "BRUNET Nicolas",
            email: "nbrunet40@neuf.fr",
            tel: "06.86.33.70.22",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1338296 U",
          },
          {
            playerName: "BROISE Gérard",
            email: "inf.broise@wanadoo.fr",
            tel: "06.61.41.55.88",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "5564257 E",
          },
        ],
      },
    ],
  },
  {
    competitionId: 11,
    season: "2024",
    championship: "Ligue",
    category: "Seniors 35+",
    gender: "Dames",
    teams: [
      {
        teamNum: 1,
        level: "Interdépartemantale 2",
        group: "O",
        path: "https://tenup.fft.fr/championnat/82363721",
        players: [
          {
            playerName: "CEDDIA Laurence",
            email: "laurenceceddia@aol.com",
            tel: "06.59.77.38.69",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7966067 T",
          },
          {
            playerName: "MARECHAL Gaëlle",
            email: "gmarechal01@gmail.com",
            tel: "06.43.84.05.43",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "7263130 F",
          },
          {
            playerName: "PONCET Florence",
            email: "fd.poncet@orange.fr",
            tel: "06.72.82.36.27",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "4108295 M",
          },
          {
            playerName: "DIETRICH Aurore",
            email: "aurorechandon@outlook.fr",
            tel: "06.41.95.08.14",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "2121271 D",
          },
        ],
      },
    ],
  },
  {
    competitionId: 12,
    season: "2024",
    championship: "Départemental",
    category: "11/12 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 2",
        group: "D",
        path: "https://tenup.fft.fr/championnat/82410345?division=123393&phase=197419&poule=448214",
        players: [
          {
            playerName: "GREHAL Leon",
            email: "maxime.grehal@gmail.com",
            tel: "06.70.53.67.90",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1022435 S",
          },
          {
            playerName: "GRIVEAU Julliann",
            email: "seixolaurie@hotmail.fr",
            tel: "06.50.55.41.97",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "3181723 U",
          },
          {
            playerName: "PALIJCZUK Milo",
            email: "lmaj.pali@gmail.com",
            tel: "06 83 82 26 97",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "1314822 D",
          },
          {
            playerName: "MITANCHEZ Gabin",
            email: "clau71@hotmail.fr",
            tel: "06.84.17.04.39",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "0598540 L",
          },
        ],
      },
    ],
  },
  {
    competitionId: 13,
    season: "2024",
    championship: "Départemental",
    category: "13/14 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "A",
        path: "https://tenup.fft.fr/championnat/82410347?division=123396&phase=197422&poule=448136",
        players: [
          {
            playerName: "SALGADO Rafael",
            email: "gmarechal01@gmail.com",
            tel: "06.43.84.05.43",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4546267 U",
          },
          {
            playerName: "PANIE César",
            email: "jeromepanie@gmail.com",
            tel: "06.88.32.78.49",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "4544557 K",
          },
        ],
      },
      {
        teamNum: 2,
        level: "Division 2",
        group: "A",
        path: "https://tenup.fft.fr/championnat/82410347?division=123395&phase=197421&poule=448219",
        players: [
          {
            playerName: "LAY Aslan",
            email: "lay.stephane@wanadoo.fr",
            tel: "06.13.63.87.01",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1047355 D",
          },
          {
            playerName: "GUILLEMIN Aloïs",
            email: "guillemin.guillaume@orange.fr",
            tel: "06.59.27.42.02",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "0595594 J",
          },
          {
            playerName: "ALBAN Quentin",
            email: "alban.ceciliaetstephane@neuf.fr",
            tel: "06.73.24.76.98",
            ranking: "40",
            rankingDouble: "18",
            licenceNum: "8022720 X",
          },
          {
            playerName: "PENIN Louis",
            email: "penin.gonzague@outlook.com",
            tel: "06.71.32.31.85",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "1033065 W",
          },
        ],
      },
    ],
  },
  {
    competitionId: 14,
    season: "2024",
    championship: "Départemental",
    category: "15/18 ans",
    gender: "Filles",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "B",
        path: "https://tenup.fft.fr/championnat/82410348?division=123658&phase=197790&poule=448771",
        players: [
          {
            playerName: "ALBAN Eléna",
            email: "alban.ceciliaetstephane@neuf.fr",
            tel: "06.35.25.88.90",
            ranking: "30",
            doubleRanking: "12",
            licenceNum: "6162636 S",
          },
          {
            playerName: "GRANGER Noémie",
            email: "vergranger@orange.fr",
            tel: "06.25.70.61.32",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "4540484 H",
          },
        ],
      },
    ],
  },
  {
    competitionId: 15,
    season: "2024",
    championship: "Départemental",
    category: "15/18 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 2",
        group: "I",
        path: "https://tenup.fft.fr/championnat/82410349?division=123656&phase=197785&poule=448763",
        players: [
          {
            playerName: "SURACE Adrien",
            email: "sandrine.surace@sfr.fr",
            tel: "06.43.70.43.56",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "1634354 W",
          },
          {
            playerName: "DEMIRAY Kenan",
            email: "kupraa@gmail.com",
            tel: "06.69.13.07.90",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "4440129 B",
          },
        ],
      },
      {
        teamNum: 2,
        level: "Division 3",
        group: "A",
        path: "https://tenup.fft.fr/championnat/82410349?division=123657&phase=197786&poule=448764",
        players: [
          {
            playerName: "CHASSANDE-PATRON Louis",
            email: "dasmure@yahoo.fr",
            tel: "06 44 74 47 33",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "0598539 K",
          },
          {
            playerName: "BERANGER Corentin",
            email: "guillaume.myriam@hotmail.fr",
            tel: "06.84.08.21.12",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "0595576 P",
          },
          {
            playerName: "MARTIN Charlie",
            email: "juliemichelfr@gmail.com",
            tel: "06.74.05.87.74",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "1990991 V",
          },
        ],
      },
    ],
  },
  {
    competitionId: 16,
    season: "2024",
    championship: "Coupe Des Dames",
    category: "Seniors",
    gender: "Dames",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "",
        path: "https://tenup.fft.fr/championnat/82407080?division=122877&phase=196738",
        players: [
          {
            playerName: "DIETRICH AURORE",
            email: "aurorechandon@outlook.fr",
            tel: "06.41.95.08.14",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "2121271 D",
          },
          {
            playerName: "DI MAJO Stéphanie",
            email: "stephanie.dimajo@orange.fr",
            tel: "06.75.09.61.11",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "2527820 E",
          },
          {
            playerName: "AMORIM Cindy",
            email: "cindy.amorim71@gmail.com",
            tel: "06.32.95.81.71",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "0615498 U",
          },
          {
            playerName: "REYDET Céline",
            email: "celine.dupui@gamil.com",
            tel: "06.32.50.32.55",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "1639152 L",
          },
          {
            playerName: "VULIN Marion",
            email: "marion.vulin@gmail.com",
            tel: "06.21.70.27.94",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "2528205 Y",
          },
          {
            playerName: "MATHIAS Axelle",
            email: "axellemathias@gmail.com",
            tel: "06.89.46.72.81",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "1752090 V",
          },
        ],
      },
    ],
  },
  {
    competitionId: 17,
    season: "2025",
    championship: "Départemental",
    category: "11/12 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 2",
        group: "C",
        path: "https://tenup.fft.fr/championnat/82490643?division=130942&phase=210097&poule=471538",
        players: [
          {
            playerName: "PALIJCZUK Milo",
            email: "lmaj.pali@gmail.com",
            tel: "06 83 82 26 97",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1314822 D",
          },
          {
            playerName: "LESBRE Maël",
            email: "elodos@hotmail.fr",
            tel: "06 42 93 06 93",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "1357142 D",
          },
          {
            playerName: "MITANCHEZ Gabin",
            email: "clau71@hotmail.fr",
            tel: "06 84 17 04 39",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "0598540 L",
          },
          {
            playerName: "ARBELLOT-REPAIRE Tom",
            email: "zazalice@hotmail.com",
            tel: "06 16 91 50 32",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "1447809 E",
          },

          {
            playerName: "GRIVEAU Julliann",
            email: "seixolaurie@hotmail.fr",
            tel: "06.50.55.41.97",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "3181723 U",
          },
        ],
      },
    ],
  },
  {
    competitionId: 18,
    season: "2025",
    championship: "Départemental",
    category: "13/14 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "B",
        path: "https://tenup.fft.fr/championnat/82490647?division=131013&phase=210189&poule=471332",
        players: [
          {
            playerName: "PANIE César",
            email: "jeromepanie@gmail.com",
            tel: "06 88 32 78 49",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "4544557 K",
          },
          {
            playerName: "SALGADO Rafael",
            email: "gmarechal01@gmail.com",
            tel: "06 43 84 05 43",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "4546267 U",
          },
          {
            playerName: "GREHAL Leon",
            email: "maxime.grehal@gmail.com",
            tel: "06.70.53.67.90",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1022435 S",
          },
          {
            playerName: "GUILLEMIN Aloïs",
            email: "guillemin.guillaume@orange.fr",
            tel: "06 59 27 42 02",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "0595594 J",
          },
        ],
      },
    ],
  },
  {
    competitionId: 19,
    season: "2025",
    championship: "Départemental",
    category: "11/14 ans",
    gender: "Filles",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "A",
        path: "https://tenup.fft.fr/championnat/82490653",
        players: [
          {
            playerName: "AUPOIL Lise",
            email: "vaupoil@aol.com",
            tel: "06 75 12 07 35",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "9888851 A",
          },
          {
            playerName: "PEREZ LOPEZ Ana",
            email: "luciedaud@hotmail.com",
            tel: "06 06 98 85 55",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1135594 R",
          },
          {
            playerName: "MARTINEZ Shany",
            email: "lisa3004@hotmail.fr",
            tel: "06 70 49 95 84",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "4411597 N",
          },
        ],
      },
    ],
  },
  {
    competitionId: 20,
    season: "2025",
    championship: "Départemental",
    category: "15/18 ans",
    gender: "Garçons",
    teams: [
      {
        teamNum: 1,
        level: "Division 3",
        group: "B",
        path: "https://tenup.fft.fr/championnat/82490662",
        players: [
          {
            playerName: "SURACE Adrien",
            email: "sandrine.surace@sfr.fr",
            tel: "06 43 70 43 56",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "1634354 W",
          },
          {
            playerName: "ROHART Vincent",
            email: "rohartsb@gmail.com",
            tel: "07 84 66 90 14",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "2408016 H",
          },
          {
            playerName: "BONNABAUD Mathis",
            email: "mathis.bonnabaud@gmail.com",
            tel: "07 84 66 90 14",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "1105693 P",
          },
          {
            playerName: "MARTINEZ Mathys",
            email: "lisa3004@hotmail.fr",
            tel: "06 70 49 95 84",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "2917110 W",
          },
          {
            playerName: "GUERBER Bastien",
            email: "olivier.karinette@gmail.com",
            tel: "06 63 51 96 42",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "2667917 J",
          },
          {
            playerName: "RIGOLLET Julien",
            email: "ludovic.rigollet@orange.fr",
            tel: "06 83 03 38 04",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "2667925 T",
          },
        ],
      },
    ],
  },
  {
    competitionId: 21,
    season: "2025",
    championship: "Interdépartemental",
    category: "Seniors 35+",
    gender: "Messieurs",
    teams: [
      {
        teamNum: 1,
        level: "Division 2",
        group: "5",
        path: "https://tenup.fft.fr/championnat/82472529?division=128658&phase=206976&poule=468234",
        players: [
          {
            playerName: "PACCOUD Yann",
            email: "yann.paccoud@gmail.com",
            tel: "07 77 31 68 46",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "0645144 T",
          },
          {
            playerName: "GATINET Francois Xavier",
            email: "fxparisien71@hotmail.fr",
            tel: "06 60 85 07 50",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "1071895 C",
          },
          {
            playerName: "THIVENT Mathieu",
            email: "mathieuthivent@gmail.com",
            tel: "06 33 59 78 55",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "3256993 J",
          },
          {
            playerName: "MARCHAND Frédéric",
            email: "fredericmarchand01@gmail.com",
            tel: "06 45 16 05 56",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1033027 E",
          },
          {
            playerName: "BROYER Alexis",
            email: "alexisbroyer@gmail.com",
            tel: "06 85 28 75 70",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "3224220 L",
          },
          {
            playerName: "CLAIR Benjamin",
            email: "clairbenjamin71@gmail.com",
            tel: "06 43 93 15 99",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1990929 C",
          },
          {
            playerName: "PETIT Hervé",
            email: "kekevalpetit@aol.com",
            tel: "06 11 03 15 18",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "0619205 Y",
          },
          {
            playerName: "CHASSANDE-PATRON Olivier",
            email: "dasmure@yahoo.fr",
            tel: "06 44 74 47 33",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "0793292 Y",
          },
          {
            playerName: "LECLERCQ Stéphane",
            email: "leclercqs25@gmail.com",
            tel: "06 08 73 25 16",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "5638808 N",
          },
          {
            playerName: "MONAVON Simon",
            email: "simon.monavon@gmail.com",
            tel: "06 81 90 74 97",
            ranking: "40",
            doubleRanking: "18",
            licenceNum: "3224266 L",
          },
        ],
      },
    ],
  },
  {
    competitionId: 22,
    season: "2025",
    championship: "Interdépartementale",
    category: "Seniors 55+",
    gender: "Messieurs",
    teams: [
      {
        teamNum: 1,
        level: "Interdépartemantale 1",
        group: "8",
        path: "https://tenup.fft.fr/championnat/82472533?division=128667&phase=207007&poule=468040",
        players: [
          {
            playerName: "CEDDIA Michel",
            email: " ceddiam@aol.com",
            tel: "06.82.94.80.94",
            ranking: "30/1",
            doubleRanking: "13",
            licenceNum: "7628206 C",
          },
          {
            playerName: "MEYER Yan",
            email: "playti.meyer837101@free.fr",
            tel: "06.68.76.00.63",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7303664 0",
          },
          {
            playerName: "SASSOT Jean-Pierre",
            email: "jean-pierre.sassot@wanadoo.fr",
            tel: "06 77 38 44 64",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "5809130 W",
          },
          {
            playerName: "BROISE Gérard",
            email: "inf.broise@wanadoo.fr",
            tel: "06.61.41.55.88",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "5564257 E",
          },
        ],
      },
    ],
  },
  {
    competitionId: 23,
    season: "2025",
    championship: "Interdépartementale",
    category: "Seniors 35+",
    gender: "Dames",
    teams: [
      {
        teamNum: 1,
        level: "Interdépartemantale 2",
        group: "21",
        path: "https://tenup.fft.fr/championnat/82472528?division=128653&phase=206955&poule=468196",
        players: [
          {
            playerName: "AMORIM Cindy",
            email: "cindy.amorim71@gmail.com",
            tel: "06 32 95 81 71",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "0615498 U",
          },
          {
            playerName: "CEDDIA Laurence",
            email: "laurenceceddia@aol.com",
            tel: "06 59 77 38 69",
            ranking: "30/2",
            doubleRanking: "14",
            licenceNum: "7966067 T",
          },
          {
            playerName: "DIETRICH Aurore",
            email: "aurorechandon@outlook.fr",
            tel: "06 41 95 08 14",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "2121271 D",
          },
          {
            playerName: "MARECHAL Gaëlle",
            email: "gmarechal01@gmail.com",
            tel: "06 43 84 05 43",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "7263130 F",
          },
          {
            playerName: "MATHIAS Axelle",
            email: "axellemathias@gmail.com",
            tel: "06 89 46 72 81",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1752090 V",
          },
          {
            playerName: "DI MAJO Stéphanie",
            email: "stephanie.dimajo@orange.fr",
            tel: "06 75 09 61 11",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "2527820 E",
          },
        ],
      },
    ],
  },
  {
    competitionId: 24,
    season: "2025",
    championship: "Coupe Des Dames",
    category: "Seniors",
    gender: "Dames",
    teams: [
      {
        teamNum: 1,
        level: "Division 1",
        group: "",
        path: "https://tenup.fft.fr/championnat/82407080?division=122877&phase=196738",
        players: [
          {
            playerName: "PALIS Charline",
            email: "charline.palis45@orange.fr",
            tel: "07 81 69 33 26",
            ranking: "30/3",
            doubleRanking: "15",
            licenceNum: "2791071 X",
          },
          {
            playerName: "DIETRICH AURORE",
            email: "aurorechandon@outlook.fr",
            tel: "06.41.95.08.14",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "2121271 D",
          },
          {
            playerName: "DI MAJO Stéphanie",
            email: "stephanie.dimajo@orange.fr",
            tel: "06.75.09.61.11",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "2527820 E",
          },
          {
            playerName: "MATHIAS Axelle",
            email: "axellemathias@gmail.com",
            tel: "06 89 46 72 81",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "1752090 V",
          },
          {
            playerName: "MARECHAL Gaëlle",
            email: "gmarechal01@gmail.com",
            tel: "06 43 84 05 43",
            ranking: "30/4",
            doubleRanking: "16",
            licenceNum: "7263130 F",
          },
          {
            playerName: "REYDET Céline",
            email: "celine.dupui@gamil.com",
            tel: "06 32 50 32 55",
            ranking: "30/5",
            doubleRanking: "17",
            licenceNum: "1639152 L",
          },
          {
            playerName: "BERLAND Fanny",
            email: "fan_berland@hotmail.com",
            tel: "06 59 47 20 60",
            ranking: "NC",
            doubleRanking: "19",
            licenceNum: "9277941 T",
          },
        ],
      },
    ],
  },
];

export default tableCompetitions;
