import imgTournoi2018 from "../../../assets/img/img-tournois/imgTournoi-2018-finale-700x492.png";
import imgTournoi2018SSD from "../../../assets/img/img-tournois/imgTournoi-2018-SSD-700x717.png";
import imgTournoi2018SSM from "../../../assets/img/img-tournois/imgTournoi-2018-SSM-700x643.png";
import imgTournoi2018SSM35 from "../../../assets/img/img-tournois/imgTournoi-2018-SSM35-700x679.png";
import imgTournoi2019 from "../../../assets/img/img-tournois/imgTournoi-2019-finale-615x531.jpg";
import imgTournoi2019SSD from "../../../assets/img/img-tournois/imgTournoi-2019-SSD-700x520.png";
import imgTournoi2019SSM from "../../../assets/img/img-tournois/imgTournoi-2019-SSM-700x847.png";
import imgTournoi2019SSM35 from "../../../assets/img/img-tournois/imgTournoi-2019-SSM35-700x520.png";
import imgTournoi2022 from "../../../assets/img/img-tournois/imgTournoi-2022-finale-856x465.png";
import imgTournoi2022SSD from "../../../assets/img/img-tournois/imgTournoi-2022-SSD-700x764.png";
import imgTournoi2022SSM from "../../../assets/img/img-tournois/imgTournoi-2022-SSM-700x824.png";
import imgTournoi2022SSM35 from "../../../assets/img/img-tournois/imgTournoi-2022-SSM35-700x602.png";
import imgTournoi2023SSD from "../../../assets/img/img-tournois/imgTournoi-2023-SSD-700x497.png";
import imgTournoi2023SSM from "../../../assets/img/img-tournois/imgTournoi-2023-SSM-700x502.png";
import imgTournoi2023SSM35 from "../../../assets/img/img-tournois/imgTournoi-2023-SSM35-700x878.png";
import imgTournoi2023 from "../../../assets/img/img-tournois/imgTournoi-2023-finale-985x595.png";
import imgTournoi2024SSD from "../../../assets/img/img-tournois/imgTournoi-2024-SSD-700x700.png";
import imgTournoi2024SSM from "../../../assets/img/img-tournois/imgTournoi-2024-SSM-700x771.png";
import imgTournoi2024SSM35 from "../../../assets/img/img-tournois/imgTournoi-2024-SSM35-700x791.png";
import imgTournoi2024 from "../../../assets/img/img-tournois/imgTournoi-2024-finale-700x595.png";
import posterTournoi2018 from "../../../assets/img/img-tournois/posterTournoi-2018-318x492.png";
import posterTournoi2019 from "../../../assets/img/img-tournois/posterTournoi-2019-375x531.png";
import posterTournoi2022 from "../../../assets/img/img-tournois/posterTournoi-2022-329x465.png";
import posterTournoi2023 from "../../../assets/img/img-tournois/posterTournoi-2023-421x595.png";
import posterTournoi2024 from "../../../assets/img/img-tournois/posterTournoi-2024-422x595.png";
import posterTournoiCovid from "../../../assets/img/img-tournois/posterTournoi-covid-1280x960.jpg";
import imgTournoiUnknown from "../../../assets/img/img-tournois/imgTournoi-unkonwn-960x960.jpg";

const tablePlayersTournois = [
  {
    id: 0,
    category: "Seniors",
    seasons: [
      {
        seasonId: 0,
        season: "2018",
        date: "08/06/2018 au 24/06/2018",
        registered: 104,
        tournamentNum: 35,
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 88,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 17,
                finalWinnerName: "BON Katia (4/6)",
                finalWinnerClub: "TC PRISSE",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "CARRON Valentine (5/6)",
                finalLoserClub: "TC NORD REVERMONT",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "AH-HONE Maéva (15/1)",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "DUPONT Julie (15/1)",
                semiFinalLoser2Club: "TC HURIGNY",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 71,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "GUINET Valentin (15/2)",
                finalLoserClub: "TC PARAY LE MONIAL",
                finalLoserSemiFinaleScore: "3/6 - 6/4 - 6/4",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "JARILLO Evan (5/6)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 16,
                finalWinnerName: "ALLIOT Jérôme (15/4)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "GAUVRIT Frédéric (15/2)",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "GONOD Stéphane (15/4)",
                semiFinalLoser1Club: "TC MANZIAT",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoi2018SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 1,
        season: "2019",
        date: "07/06/2019 au 23/06/2019",
        registered: 77,
        tournamentNum: 36,
        poster: posterTournoi2019,
        photoFinale: imgTournoi2019,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 61,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 17,
                finalWinnerName: "BORNAREL Tatiana (4/6)",
                finalWinnerClub: "TC MACON",
                finalScore: "6/3 - 4/6 - 7/6(6)",
                finalWinnerSemiFinalScore: "6/3 - 6/1",
                finalLoserName: "MOTTE Virginia (4/6)",
                finalLoserClub: "TC BEAUNE",
                finalLoserSemiFinaleScore: "6/4 - 5/7 - 6/4",
                semiFinalLoser1Name: "OCHOA MARTINEZ Angelica (4/6)",
                semiFinalLoser1Club: "AC LEMPDES COL",
                semiFinalLoser2Name: "AH-HONE Maéva (15)",
                semiFinalLoser2Club: "TC HURIGNY",
                photoFinalEvent: imgTournoi2019SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 44,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "6/0 - 6/9",
                finalWinnerSemiFinalScore: "6/2 - 6/0",
                finalLoserName: "JARILLO Evan (5/6)",
                finalLoserClub: "TC FEILLENS",
                finalLoserSemiFinaleScore: "6/2 - 4/6 - 2/1 Ab.",
                semiFinalLoser1Name: "JARILLO Lonny (15)",
                semiFinalLoser1Club: "6/2 - 6/0",
                semiFinalLoser2Name: "AKO Maxime (15/1)",
                semiFinalLoser2Club: "TC MACON",
                photoFinalEvent: imgTournoi2019SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 16,
                finalWinnerName: "LEFEBVRE Tony (15/4)",
                finalWinnerClub: "TC MANZIAT",
                finalScore: "6/4 - 6/3",
                finalWinnerSemiFinalScore: "6/3 - 6/1",
                finalLoserName: "ALLIOT Jérôme (15/3)",
                finalLoserClub: "TC ATTIGNAT",
                finalLoserSemiFinaleScore: "6/3 - 6/3",
                semiFinalLoser1Name: "BURG Laurent (15/5)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "GORDONNAT Eric (15/5)",
                semiFinalLoser2Club: "TC MANZIAT",
                photoFinalEvent: imgTournoi2019SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 2,
        season: "2020",
        date: "13/06/2020 au 28/06/2020",
        registered: 0,
        tournamentNum: "Aucune",
        poster: posterTournoiCovid,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
        ],
      },
      {
        seasonId: 3,
        season: "2021",
        date: "12/06/2021 au 27/06/2021",
        registered: 0,
        tournamentNum: "Aucune",
        poster: posterTournoiCovid,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
        ],
      },
      {
        seasonId: 4,
        season: "2022",
        date: "10/06/2022 au 26/06/2022",
        registered: 87,
        tournamentNum: 37,
        poster: posterTournoi2022,
        photoFinale: imgTournoi2022,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors",
            registered: 71,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 14,
                finalWinnerName: "BON Katia - 5/6",
                finalWinnerClub: "TC PRISSE",
                finalScore: "6/1 - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "CASTELLETTO Joanna - 15/1",
                finalLoserClub: "TC PRISSE",
                finalLoserSemiFinaleScore: "w.o.",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "DUPONT Julie - 15/1",
                semiFinalLoser2Club: "TC HURIGNY",
                photoFinalEvent: imgTournoi2022SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 57,
                finalWinnerName: "BLOT Florian (4/6)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/0 - 1/0 Ab.)",
                finalWinnerSemiFinalScore: "3/6 - 6/3 - 7/5",
                finalLoserName: "BEYNEL Guillaume (4/6)",
                finalLoserClub: "PLANETE TENNIS",
                finalLoserSemiFinaleScore: "6/3 - 7/5",
                semiFinalLoser1Name: "SAUDRAIS Sohel (5/6)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "JARILLO Lonny (15/2)",
                semiFinalLoser2Club: "TC FEILLENS",
                photoFinalEvent: imgTournoi2022SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 16,
                finalWinnerName: "SIRAUD Robert (15/5)",
                finalWinnerClub: "TC3 BAGE DOMMARTIN",
                finalScore: "6/4 - 3/1 Ab.",
                finalWinnerSemiFinalScore: "6/1 - 7/5",
                finalLoserName: "LEFEBVRE Tony (15/4)",
                finalLoserClub: "TC3 MANZIAT",
                finalLoserSemiFinaleScore: "w.o.",
                semiFinalLoser1Name: "GORDONNAT Eric (15/5)",
                semiFinalLoser1Club: "TC MANZIAT",
                semiFinalLoser2Name: "GROSJEAN Cédric (15/2)",
                semiFinalLoser2Club: "TC VAL DE SAONE CHAL.",
                photoFinalEvent: imgTournoi2022SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 5,
        season: "2023",
        date: "08/06/2023 au 25/06/2023",
        registered: 98,
        tournamentNum: 38,
        poster: posterTournoi2023,
        photoFinale: imgTournoi2023,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 85,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 19,
                finalWinnerName: "GILLET Sabrina (4/6)",
                finalWinnerClub: "TC HURIGNY",
                finalScore: "7/6(7) - 4/6 - 6/3",
                finalWinnerSemiFinalScore: "6/1 - 6/3",
                finalLoserName: "DUPONT Julie (4/6)",
                finalLoserClub: "TC HURIGNY",
                finalLoserSemiFinaleScore: "6/4 - 2/6 - 6/1",
                semiFinalLoser1Name: "OCHOA MARTINEZ Angelica (5/6)",
                semiFinalLoser1Club: "TC SAVIGNOIS",
                semiFinalLoser2Name: "CASTELLETTO Joanna (5/6)",
                semiFinalLoser2Club: "TC PRISSE",
                photoFinalEvent: imgTournoi2023SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 66,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "6/3 - 6/2",
                finalWinnerSemiFinalScore: "6/2-7/6(9)",
                finalLoserName: "BALESTE Damien (4/6)",
                finalLoserClub: "TC MACON",
                finalLoserSemiFinaleScore: "6/0 - 6/0",
                semiFinalLoser1Name: "BLOT Florian (5/6)",
                semiFinalLoser1Club: "TC BOURG EN BRESSE",
                semiFinalLoser2Name: "MADRID TORRES Miguel AAngel (4/6)",
                semiFinalLoser2Club: "CLUB ETRANGER FEDERATION",
                photoFinalEvent: imgTournoi2023SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 13,
                finalWinnerName: "FANON Yann (15/2)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/2 - 6/4",
                finalWinnerSemiFinalScore: "6/4 - 6/3",
                finalLoserName: "HOSSEPIED Ronan (15/3)",
                finalLoserClub: "TC3 BAGE DOMMARTIN",
                finalLoserSemiFinaleScore: "7/5 - 4/6 - 6/2",
                semiFinalLoser1Name: "GUILLEMAUD David (15/5)",
                semiFinalLoser1Club: "TC VEYLE SAONE",
                semiFinalLoser2Name: "LEFBVRE Tony (15/5)",
                semiFinalLoser2Club: "TC MANZIAT",
                photoFinalEvent: imgTournoi2023SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 6,
        season: "2024",
        date: "06/06/2024 au 23/06/2024",
        registered: 105,
        tournamentNum: 39,
        poster: posterTournoi2024,
        photoFinale: imgTournoi2024,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 82,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 14,
                finalWinnerName: "ODET Louise-Line - 15",
                finalWinnerClub: "TC MACON",
                finalScore: "6/7(5) - 6/0 - 7/5",
                finalWinnerSemiFinalScore: "6/0 - 6/3",
                finalLoserName: "GILLET Sabrina - 4/6",
                finalLoserClub: "HURIGNY TC",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "PACCAUD Lucie - 15/3",
                semiFinalLoser1Club: "TC FEILLENS",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoi2024SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 68,
                finalWinnerName: "LAFLECHE Thibaut (4/6)",
                finalWinnerClub: "TC ATTIGNAT",
                finalScore: "6/3 - 0/6 - 6/4",
                finalWinnerSemiFinalScore: "6/1-7/6(5)",
                finalLoserName: "LECLERCQ Kellian (4/6)",
                finalLoserClub: "TC MONTMERLE sur Saône",
                finalLoserSemiFinaleScore: "3/6 - 6/4 - 6/4",
                semiFinalLoser1Name: "DESSERTENNE Quentin (15/1)",
                semiFinalLoser1Club: "TC MACON",
                semiFinalLoser2Name: "MOUROT Robin (5/6)",
                semiFinalLoser2Club: "ALC LONGVIC",
                photoFinalEvent: imgTournoi2024SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 23,
                finalWinnerName: "FANON Yann (15/2)",
                finalWinnerClub: "TC BOURG-EN-BRESSE",
                finalScore: "6/2 - 6/1",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "HOSSEPIED Ronan (15/3)",
                finalLoserClub: "TC3 BAGE DOMMARTIN",
                finalLoserSemiFinaleScore: "6/3 - 6/1",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "GUILLEMAUD David (15/5)",
                semiFinalLoser2Club: "TC VEYLE SAONE",
                photoFinalEvent: imgTournoi2024SSM35,
              },
            ],
          },
        ],
      },
      {
        seasonId: 7,
        season: "2025",
        date: "07/06/2025 au 23/06/2025",
        registered: 0,
        tournamentNum: 40,
        poster: imgTournoiUnknown,
        photoFinale: imgTournoiUnknown,
        competitions: [
          {
            competitionId: 1,
            competition: "Seniors NC à 4/6",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Dames",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 2,
                eventLabel: "Simple Messieurs",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
              {
                eventId: 3,
                eventLabel: "Simple Messieurs 35+",
                registered: 0,
                finalWinnerName: "-",
                finalWinnerClub: "-",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "-",
                finalLoserClub: "-",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "-",
                semiFinalLoser1Club: "-",
                semiFinalLoser2Name: "-",
                semiFinalLoser2Club: "-",
                photoFinalEvent: imgTournoiUnknown,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 0,
    category: "Jeunes",
    seasons: [
      {
        seasonId: 0,
        season: "2018",
        date: "25/08/2018 au 02/09/2018",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Défi VERT - 9/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
      {
        seasonId: 1,
        season: "2019",
        date: "24/08/2019 au 31/08/2019",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Galaxie ORANGE - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 5,
            competition: "Galaxie VERT - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
      {
        seasonId: 2,
        season: "2020",
        date: "22/08/2020 au 30/08/2020",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Galaxie ORANGE - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 5,
            competition: "Galaxie VERT - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
      {
        seasonId: 3,
        season: "2021",
        date: "21/08/2021 au 29/08/2021",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Galaxie ORANGE - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 5,
            competition: "Galaxie VERT - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
      {
        seasonId: 4,
        season: "2022",
        date: "20/08/2022 au 27/08/2022",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Galaxie ORANGE - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 5,
            competition: "Galaxie VERT - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
      {
        seasonId: 5,
        season: "2023",
        date: "19/08/2023 au 27/08/2023",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Galaxie ORANGE - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 5,
            competition: "Galaxie VERT - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
      {
        seasonId: 6,
        season: "2024",
        date: "24/08/2024 au 01/09/2024",
        registered: 0,
        tournamentNum: "-",
        poster: posterTournoi2018,
        photoFinale: imgTournoi2018,
        competitions: [
          {
            competitionId: 1,
            competition: "11/12 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 2,
            competition: "13/14 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 3,
            competition: "15/16 ans - NC à 15/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (5/6)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (15/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (4/6)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (15/2)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (15/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (5/6)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 4,
            competition: "Galaxie ORANGE - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
          {
            competitionId: 5,
            competition: "Galaxie VERT - 8/10 ans - NC à 30/1",
            registered: 0,
            events: [
              {
                eventId: 1,
                eventLabel: "Simple Filles",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSD,
              },
              {
                eventId: 2,
                eventLabel: "Simple Garçons",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
              {
                eventId: 3,
                eventLabel: "Simple Mixte",
                registered: 0,
                finalWinnerName: "- (30/1)",
                finalWinnerClub: "TC -",
                finalScore: "-",
                finalWinnerSemiFinalScore: "-",
                finalLoserName: "- (30/1)",
                finalLoserClub: "TC -",
                finalLoserSemiFinaleScore: "-",
                semiFinalLoser1Name: "- (30/1)",
                semiFinalLoser1Club: "TC -",
                semiFinalLoser2Name: "- (30/1)",
                semiFinalLoser2Club: "TC -",
                photoFinalEvent: imgTournoi2018SSM,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default tablePlayersTournois;
