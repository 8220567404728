const tabTarifsProduits = [
  {
    validityDate: "2024-12-06",
    isValid: true,
    familles: [
      {
        id: 0,
        famille: "fromage",
        produits: [
          {
            produitId: 0,
            libelle: "Comté 6 mois",
            unite: "250 g",
            prixUnitaire: 4.5,
          },
          {
            produitId: 1,
            libelle: "Comté 6 mois",
            unite: "500 g",
            prixUnitaire: 9.0,
          },
          {
            produitId: 2,
            libelle: "Comté Juraflore Extra Fruité 12 mois",
            unite: "500 g",
            prixUnitaire: 11,
          },
          // {
          //   produitId: 3,
          //   libelle: "Comté Extra Haut Jura 16 mois",
          //   unite: "250 g",
          //   prixUnitaire: 6.0,
          // },
          {
            produitId: 4,
            libelle: "Comté Extra Haut Jura 16 mois",
            unite: "500 g",
            prixUnitaire: 12,
          },
          {
            produitId: 5,
            libelle: "Morbier au lait cru",
            unite: "500 g",
            prixUnitaire: 9.0,
          },
          {
            produitId: 6,
            libelle: "Bleu de Gex",
            unite: "500 g",
            prixUnitaire: 9.5,
          },
          {
            produitId: 7,
            libelle: "Raclette au lait cru",
            unite: "500 g",
            prixUnitaire: 8.5,
          },
          {
            produitId: 8,
            libelle: "Tomme mousseron",
            unite: "500 g",
            prixUnitaire: 11,
          },
          {
            produitId: 9,
            libelle: "Mont d'Or petit modèle",
            unite: "500 g",
            prixUnitaire: 11,
          },
          {
            produitId: 10,
            libelle: "Mamirolle",
            unite: "300 g",
            prixUnitaire: 6,
          },
          {
            produitId: 11,
            libelle: "Polinois",
            unite: "300 g",
            prixUnitaire: 5.5,
          },
          {
            produitId: 12,
            libelle: "Grimont",
            unite: "300 g",
            prixUnitaire: 5.5,
          },
        ],
      },
      {
        id: 1,
        famille: "saucisse",
        produits: [
          {
            produitId: 0,
            libelle: "Saucisse de Morteau",
            unite: "400 g",
            prixUnitaire: 10.5,
          },
          {
            produitId: 1,
            libelle: "Saucisse au Comté",
            unite: "400 g",
            prixUnitaire: 10.5,
          },
        ],
      },
      {
        id: 2,
        famille: "vin",
        produits: [
          {
            produitId: 0,
            unite: "1",
            libelle: "Beaujolais Villages Blanc",
            prixUnitaire: 9.0,
          },
          {
            produitId: 1,
            unite: "1",
            libelle: "Beaujolais Villages Rouge | Or International Gamay",
            prixUnitaire: 8.0,
          },
          {
            produitId: 2,

            unite: "1",
            libelle: "Juliénas | La Petite Cabane Or Grands Vins Beaujolais",
            prixUnitaire: 10.0,
          },
          {
            produitId: 3,
            unite: "1",
            libelle: "Morgon | Bouby Or International Gamay",
            prixUnitaire: 10.0,
          },
          {
            produitId: 4,
            unite: "1",
            libelle: "Fleurie | Vieilles Vignes Or International Gamay",
            prixUnitaire: 10.5,
          },
          {
            produitId: 5,
            unite: "1",
            libelle:
              "Moulin à Vent | Vieilles Vignes Or Grands Vins du Beaujolais",
            prixUnitaire: 11.5,
          },
        ],
      },
      {
        id: 3,
        famille: "gaufre",
        produits: [
          {
            produitId: 0,
            unite: "6",
            type: "Gaufre",
            libelle: "Gaufres bressanes",
            prixUnitaire: 4,
          },
        ],
      },
    ],
  },
  {
    validityDate: "2025-04-06",
    isValid: false,
    familles: [
      {
        id: 0,
        famille: "Viande",
        produits: [
          {
            produitId: 0,
            libelle: "Comté 6 mois",
            unite: "250 g",
            prixUnitaire: 4.5,
          },
          {
            produitId: 1,
            libelle: "Comté 6 mois",
            unite: "500 g",
            prixUnitaire: 9.0,
          },
          {
            produitId: 2,
            libelle: "Comté Juraflore Extra Fruité 12 mois",
            unite: "500 g",
            prixUnitaire: 10.5,
          },
          {
            produitId: 3,
            libelle: "Comté Extra Haut Jura 16 mois",
            unite: "250 g",
            prixUnitaire: 6.0,
          },
          {
            produitId: 4,
            libelle: "Comté Extra Haut Jura 16 mois",
            unite: "500 g",
            prixUnitaire: 11.5,
          },
          {
            produitId: 5,
            libelle: "Morbier au lait cru",
            unite: "500 g",
            prixUnitaire: 9.0,
          },
          {
            produitId: 6,
            libelle: "Bleu de Gex",
            unite: "500 g",
            prixUnitaire: 9.0,
          },
          {
            produitId: 7,
            libelle: "Raclette au lait cru",
            unite: "500 g",
            prixUnitaire: 8.5,
          },
          {
            produitId: 8,
            libelle: "Tomme mousseron",
            unite: "500 g",
            prixUnitaire: 10.5,
          },
          {
            produitId: 9,
            libelle: "Mont d'Or petit modèle",
            unite: "500 g",
            prixUnitaire: 10.5,
          },
          {
            produitId: 10,
            libelle: "Mamirolle",
            unite: "300 g",
            prixUnitaire: 5.5,
          },
          {
            produitId: 11,
            libelle: "Polinois",
            unite: "300 g",
            prixUnitaire: 5.5,
          },
          {
            produitId: 12,
            libelle: "Grimont",
            unite: "300 g",
            prixUnitaire: 5.5,
          },
        ],
      },
      {
        id: 1,
        famille: "saucisse",
        produits: [
          {
            produitId: 0,
            libelle: "Saucisse de Morteau",
            unite: "400 g",
            prixUnitaire: 10.5,
          },
          {
            produitId: 1,
            libelle: "Saucisse au Comté",
            unite: "400 g",
            prixUnitaire: 10.5,
          },
        ],
      },
      {
        id: 2,
        famille: "vin",
        produits: [
          {
            produitId: 0,
            unite: "1",
            libelle: "Beaujolais Villages Blanc",
            prixUnitaire: 9.0,
          },
          {
            produitId: 1,
            unite: "1",
            libelle: "Beaujolais Villages Rouge | Or International Gamay",
            prixUnitaire: 8.0,
          },
          {
            produitId: 2,

            unite: "1",
            libelle: "Juliénas | La Petite Cabane Or Grands Vins Beaujolais",
            prixUnitaire: 10.0,
          },
          {
            produitId: 3,
            unite: "1",
            libelle: "Morgon | Bouby Or International Gamay",
            prixUnitaire: 10.0,
          },
          {
            produitId: 4,
            unite: "1",
            libelle: "Fleurie | Vieilles Vignes Or International Gamay",
            prixUnitaire: 10.5,
          },
          {
            produitId: 5,
            unite: "1",
            libelle:
              "Moulin à Vent | Vieilles Vignes Or Grands Vins du Beaujoalmis",
            prixUnitaire: 11.5,
          },
        ],
      },
      {
        id: 3,
        famille: "gaufre",
        produits: [
          {
            produitId: 0,
            unite: "6",
            type: "Gaufre",
            libelle: "Gaufres bressanes",
            prixUnitaire: 4,
          },
        ],
      },
    ],
  },
];

export default tabTarifsProduits;
