import Fred from "../../../assets/img/img-bureau/idFred.jpg";
import Galaxie from "../../../assets/img/img-enseignement/galaxie.jpg";
import CriteresGalaxie from "../../../assets/img/img-enseignement/criteresGalaxie.png";
import AfficheGalaxie from "../../../assets/img/img-enseignement/afficheGalaxieTennis.jpg";
import Ados from "../../../assets/img/img-enseignement/ados.jpg";
import Adulte1 from "../../../assets/img/img-enseignement/tennisadulte1.jpg";
import Adulte2 from "../../../assets/img/img-enseignement/tennisadulte2.jpg";
import StageP1 from "../../../assets/img/img-enseignement/stages0.jpg";
import StageP2 from "../../../assets/img/img-enseignement/stages1.jpg";
import Label from "../../../assets/img/img-enseignement/tennisSanteLabel.png";
import TennisSante from "../../../assets/img/img-enseignement/tennisSante.jpeg";
import TennisSanteAffiche from "../../../assets/img/img-enseignement/tennisSanteAffiche.jpeg";
import TennisSanteArticle from "../../../assets/img/img-enseignement/tennisSanteArticle.jpg";
import Pickleball from "../../../assets/img/img-animations/06_pickleball.png";
import PickleballJeu from "../../../assets/img/img-pickleball/imgPickleball-jeu-450x300.png";
import PickleballRegles from "../../../assets/img/img-pickleball/imgPickleball-reglesA5-595x420.png";
import PickleballTerrain from "../../../assets/img/img-pickleball/imgPickleball-terrain-700x386.png";
import PickleballRaquette from "../../../assets/img/img-pickleball/imgPickleball-raquetteBalle-817x1280.png";
import PickleballBalle from "../../../assets/img/img-pickleball/imgPickleball-balle-989x1280.jpg";

const tabEnseignement = [
  { id: "Fred", image: Fred },
  { id: "Galaxie", image: Galaxie },
  { id: "CriteresGalaxie", image: CriteresGalaxie },
  { id: "AfficheGalaxie", image: AfficheGalaxie },
  { id: "Ados", image: Ados },
  { id: "Adulte1", image: Adulte1 },
  { id: "Adulte2", image: Adulte2 },
  { id: "StageP1", image: StageP1 },
  { id: "StageP2", image: StageP2 },
  { id: "Label", image: Label },
  { id: "TennisSante", image: TennisSante },
  { id: "TennisSanteAffiche", image: TennisSanteAffiche },
  { id: "TennisSanteArticle", image: TennisSanteArticle },
  { id: "Pickleball", image: Pickleball },
  { id: "PickleballJeu", image: PickleballJeu },
  { id: "PickleballRegles", image: PickleballRegles },
  { id: "PickleballTerrain", image: PickleballTerrain },
  { id: "PickleballRaquette", image: PickleballRaquette },
  { id: "PickleballBalle", image: PickleballBalle },
];

export default tabEnseignement;
